.page-content-container{
    display: flex;
    justify-content: center;
    min-height: 85vh;
}

.page-main-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}