.container{
    width: 100px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
}

.container img{
    height: 60px;
}